<template>
	<div class="pa-4">
		<v-card class="text-center">
			<div class="px48 font-fat primary--text">Not Found</div>
			<div class="px64 font-bold">404</div>
			<div class="px22 py-4">
				Requested page is not found!
			</div>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "NotFound"
}
</script>

<style scoped lang="scss">
@import "src/styles/fonts";
</style>
